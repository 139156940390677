import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box } from 'rebass/styled-components';
import Markdown from 'react-markdown';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';

const Contact = injectIntl(({ intl }) => (
  <div>
    <SEO title={`Tugboat Coding - ${intl.formatMessage({ id: 'contact.heading' })}`} />
    <Box my="32px">
      <h1><FormattedMessage id="contact.heading" /></h1>
    </Box>
    <Markdown source={intl.formatMessage({ id: 'contact.body' })} />
  </div>
));

const ContactPage = () => (
  <Container>
    <Contact />
  </Container>
);

export default ContactPage;
